import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as Logo } from "../../assets/icons/animation-logo.svg";
import { ActionButtons } from "./ActionButtons";
import { getUser } from "../../services/utils";
import MicRecorder from "mic-recorder-to-mp3";
import { usePraxisCall } from "../praxis-play/context/praxiscall-context";
import { api } from "../../services/api";
import useResponsive from "../../services/useResponsive";
import { PraxisLoading } from "./PraxisLoading";
import { useUser } from "../../services/user-context";
import { AudioPlayer } from "./components/AudioPlayer";
import ClockTimer from "./components/ClockTimer";

async function* getChunks(stream) {
  for await (const chunk of stream) {
    yield chunk[0];
  }
}

const recorder = new MicRecorder({
  bitRate: 128,
});

const SILENCE_TIME = 2000; // 2 sec

const audioPlayer = new AudioPlayer();

export function PraxisCall() {
  const user = getUser();
  const { userInfo } = useUser() as any;
  const { isMobile } = useResponsive();
  const { callDetails, onDisconnect, callStatus } = usePraxisCall() as any;
  const welcomeSpeech = useRef<boolean>(false);
  const timeCountInterval = useRef<any>();
  const silenceTime = useRef<number>(0);
  const isCallEnded = useRef<boolean>(false);
  const [isRecording, setIsRecording] = useState(false);
  const isUploading = useRef<boolean>(false);
  const isMutedRef = useRef<boolean>(true);
  const harkRef = useRef<any>();
  const streamRef = useRef<any>();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [showCaption, setShowCaption] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState(false);

  const uploadAudio = async (blob: any) => {
    try {
      setUploading(true);
      isUploading.current = true;
      const formData = new FormData();
      const file = new File([blob], "audio.mp3", {
        type: "audio/mp3",
      });
      formData.append("file", file);
      const response = await api.uploadCallPart(callDetails.id, formData);
      if (isCallEnded.current) {
        // no need to process response as call is already disconnected
        return;
      }
      let total = "";
      const b = await response.blob();
      const fileReader = new FileReader();
      const reader = b.stream().getReader();
      const decoder = new TextDecoder();
      const list: any = [];
      let text = "";
      fileReader.readAsArrayBuffer(b);
      fileReader.onload = function () {
        audioPlayer.enqueueBuffer(fileReader.result);
      };
      // while (true) {
      //   const { done, value: chunk } = await reader.read();
      //   if (done) {
      //     // add remaining text to list
      //     // check if we have anyhing in chunk or not
      //     // text + chunk
      //     // const decodedValue = decoder.decode(chunk);
      //     // total += decodedValue;
      //     // text += decodedValue;
      //     // if (text) {
      //     //   list.push(text);
      //     // }
      //     // for (const item of list) {
      //     //   speak(item);
      //     // }
      //     // const buff = reader.readAsArrayBuffer(chunk);
      //     console.log("chunk", chunk);
      //     audioPlayer.enqueueBuffer(chunk);
      //     break;
      //   }

      //   // it should club 2-3 decoded chunks together
      //   // push in list
      //   // join and decode
      //   // text += decoder.decode(chunk);
      //   // check if sentence is complete or not
      //   // consider ., !, ? as end of sentence
      //   // if yes, push in list
      //   // reset text
      //   // if (text.includes(".") || text.includes("!") || text.includes("?")) {
      //   //   // cut the text till the end of sentence consider ., !, ? as end of sentence and last occurance of it
      //   //   // and rest of the text will be continued in next iteration
      //   //   const lastDot = text.lastIndexOf(".");
      //   //   const lastExclamation = text.lastIndexOf("!");
      //   //   const lastQuestion = text.lastIndexOf("?");
      //   //   const last = Math.max(lastDot, lastExclamation, lastQuestion);
      //   //   list.push(text.slice(0, last + 1));
      //   //   text = text.slice(last + 1);
      //   // }

      //   // const decodedValue = decoder.decode(chunk);
      //   // total += decodedValue;
      //   // if (list[0] && speechSynthesis.speaking === false) {
      //   //   speak(list[0]);
      //   //   list.splice(0, 1);
      //   // }
      // }
      // once we have all the text, send to server
      // if ([200, 201].includes(response.status) && total) {
      //   await api.sendFullTextResponse(callDetails.id, {
      //     response: total,
      //   });
      // }
      setUploading(false);
      isUploading.current = false;
    } catch (error) {
      setUploading(false);
      isUploading.current = false;
      console.log(error);
    }
  };

  const startRecording = () => {
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const endRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setIsRecording(false);
        const blobURL = URL.createObjectURL(blob);
        // console.log(blobURL);
        // download(blobURL, "test.mp3");
        // let a = document.createElement("a");
        // document.body.appendChild(a);
        // a.href = blobURL;
        // a.download = "test.mp3";
        // a.click();
        // window.URL.revokeObjectURL(blobURL);
        uploadAudio(blob);
      });
  };

  const handleClose = async () => {
    // console.log("disconnect");
    isCallEnded.current = true;
    speechSynthesis.cancel();

    // console.log("harkRef.current", harkRef.current);
    if (harkRef.current) {
      harkRef.current.stop();
      harkRef.current.suspend();
      harkRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track: any) => {
        track.stop();
        streamRef.current.removeTrack(track);
      });
      streamRef.current = null;
    }
    await onDisconnect();
  };

  const handleSpeak = async (userId) => {
    isUploading.current = true;
    setIsScreenLoading(true);
    const response = await api.intro(userId);
    setIsScreenLoading(false);
    const b = await response.blob();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(b);
    fileReader.onload = function () {
      isUploading.current = false;
      audioPlayer.enqueueBuffer(fileReader.result);
    };
  };

  const handleRecord = () => {
    if (
      !audioPlayer.isPlaying &&
      welcomeSpeech.current &&
      !isUploading.current
    ) {
      if (isRecording) {
        endRecording();
      } else {
        startRecording();
      }
    }
    // if (isMuted) {
    //   setIsMuted(false);
    //   harkRef.current.resume();
    //   isMutedRef.current = false;
    // } else {
    //   setIsMuted(true);
    //   harkRef.current.suspend();
    //   isMutedRef.current = true;
    // }
  };

  // // speak welcome message when component mounts only once
  useEffect(() => {
    if (!welcomeSpeech.current && !isScreenLoading) {
      welcomeSpeech.current = true;
      isCallEnded.current = false;
      handleSpeak(userInfo?.id);
      // speak(`Hello ${userInfo?.firstName}, welcome to Praxis Play`, true);
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (audioPlayer.isPlaying) {
        setIsSpeaking(true);
      } else {
        setIsSpeaking(false);
      }
    }, 30);
  }, []);

  return (
    <div className="fixed z-[9] top-0 bottom-0 left-0 right-0">
      <div className="w-full h-full left-0 top-0 absolute bg-zinc-300 rounded-xl" />
      <div className="w-60 h-full left-[1341.79px] top-[-15.71px] absolute bg-gradient-to-b from-neutral-300 via-gray-300 to-gray-300" />
      <div className="w-64 h-full left-[-8.16px] top-[-34.27px] absolute bg-gradient-to-b from-neutral-300 via-gray-300 to-gray-300" />
      <div className="w-full h-full left-0 top-0 absolute bg-callScreen rounded-xl" />
      <div className="absolute top-0 bottom-0 h-full w-full">
        <button
          onClick={handleClose}
          className="w-10 h-10 p-2 bg-white rounded-3xl justify-start items-start gap-2 flex absolute right-3.5 top-3.5"
        >
          <CloseIcon className="w-6 h-6" />
        </button>
        {!isScreenLoading && <ClockTimer />}
        <ActionButtons
          onDisconnect={handleClose}
          onVideo={() => {}}
          onMic={() => handleRecord()}
          onSubtitle={() => setShowCaption(!showCaption)}
          isRecording={isRecording}
        />
        {/* <div
          style={{
            background: isMobile
              ? "#DEDCDB"
              : "linear-gradient(180deg, #DEDCDB 0%, #DCDAD9 20%, #DCD8D7 42.5%, #D8D6D4 52.5%, #D6D2D1 60%, #D5D1CF 80%, #D3CECC 100%)",
          }}
          className="h-full  md:h-auto flex md:block items-end"
        >
          <div className="flex h-full lg:hidden lg:items-end lg:w-[calc(100vw-500px)] lg:mx-auto lg:justify-center lg:max-w-[45%]  desktop3:max-w-[48%] desktop4:max-w-[55%] desktop5:max-w-[60%]">
            <video
              ref={videoElement}
              id="video-element"
              className="h-full w-auto"
              autoPlay
              playsInline
            ></video>
          </div>
        </div> */}
        <div
          style={{
            background:
              "linear-gradient(180deg, #DEDCDB 0%, #DCDAD9 20%, #DCD8D7 42.5%, #D8D6D4 52.5%, #D6D2D1 60%, #D5D1CF 80%, #D3CECC 100%)",
          }}
          className="h-full"
        >
          <div
            className={`hidden lg:flex items-center h-full lg:justify-center ${
              isSpeaking || isRecording ? "logo-speaking" : ""
            }`}
            style={{
              backgroundImage: "url(/images/audio-bg.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/** animate scale animation while speaking */}
            <Logo
              className={`${isSpeaking || isRecording ? "logo-speaking" : ""}`}
            />
            {/* <img
              src="/images/character2.png"
              alt="character"
              className="h-full w-auto"
              onLoad={() => setIsScreenLoading(false)}
            /> */}
          </div>
        </div>
      </div>
      {isScreenLoading && <PraxisLoading />}
    </div>
  );
}
