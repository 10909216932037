import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { Login } from "./auth/login/Login";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { Register } from "./auth/register/Register";
import { PraxisPlay } from "./praxis-play/PraxisPlay";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route path="/app" element={<Main />}>
        <Route
          path="praxisplay"
          element={
            <SuspenseWrapper>
              <PraxisPlay />
            </SuspenseWrapper>
          }
        />
        {/* <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        /> */}
      </Route>
    </Routes>
  );
};
export default RoutesList;
