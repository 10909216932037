import axios from "axios";
import apiClient, { getToken } from "./axios";
import { BACKEND_URL } from "../env";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  createCharacter: (data: any) => {
    return apiClient.post("/characters", data);
  },
  getCharacters: () => {
    return apiClient.get("/characters");
  },
  startCall: (data: any) => {
    return apiClient.post("/call", data);
  },
  endCall: (callId: string) => {
    return apiClient.patch(`/call/${callId}`, {
      isEnded: true,
    });
  },
  getReports: (callId: string) => {
    // /call/{id}/reports
    return apiClient.get(`/call/${callId}/reports`);
  },
  sendFullTextResponse: (callId: string, data: any) => {
    return apiClient.post(`/call/${callId}/query-response`, data);
  },
  uploadCallPart: (callId: string, form: any) => {
    // /call/{id}/response
    return fetch(`${BACKEND_URL}call/${callId}/response`, {
      method: "POST",
      headers: {
        Authorization: `${getToken()}`,
      },
      body: form,
    });
    // return axios.post(`/call/${callId}/response`, form, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: getToken(),
    //     responseType: "stream",
    //   },
    //   baseURL: BACKEND_URL,
    // });
  },
  socialLogin: (data: any) => {
    return apiClient.post("/auth/login/social", data);
  },
  initEmailVerification: (id: any) => {
    return apiClient.post(`/users/${id}/email/verify/initiate`);
  },
  verifyEmail: (id: any, data: any) => {
    return apiClient.post(`/users/${id}/email/verify`, data);
  },
  signup: (data: any) => {
    return apiClient.post("/auth/register", data).then((res) => res.data);
  },
  logout: () => {
    return apiClient.post("/auth/logout").then((res) => res.data);
  },
  changePassword: (
    id: string,
    data: {
      oldPassword: string;
      newPassword: string;
    }
  ) => {
    return apiClient.patch(`/users/${id}/password`, data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveLastActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  saveUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  getProgressDashboard: (userId: string, month: string, year: string) => {
    return apiClient
      .get(`/users/${userId}/progress-dashboard?month=${month}&year=${year}`)
      .then((r) => r.data);
  },
  getDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((r) => r.data);
  },
  intro: (userId: string) => {
    return fetch(`${BACKEND_URL}users/${userId}/intro`, {
      method: "POST",
      headers: {
        Authorization: `${getToken()}`,
      },
    });
  },
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;

    return apiClient.get(url).then((r) => r.data);
  },

  //d-id api
  connectStream: (data: any) => {
    let url = `/did/streams`;
    return apiClient.post(url, data);
  },
  connectIceCandidate: (streamId: string, data: any) => {
    let url = `/did/streams/${streamId}/ice`;
    return apiClient.post(url, data);
  },
  connectSdp: (streamId: string, data: any) => {
    let url = `/did/streams/${streamId}/sdp`;
    return apiClient.post(url, data);
  },
  getStream: (streamId: string, data: any) => {
    let url = `/did/streams/${streamId}`;
    return apiClient.post(url, data);
  },
};
