import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import { ReactComponent as EmptyStarIcon } from "../../../assets/icons/empty-star.svg";
import { ReactComponent as HalfStarIcon } from "../../../assets/icons/half-star.svg";

export function Rating({ value }: { value: number }) {
  const totalFullStars = Math.floor(value);
  const hasHalfStar = value - totalFullStars > 0;
  return (
    <div className="flex items-center">
      <div className="text-[#343434] text-base">{value}</div>
      <div className="ml-3 flex items-center gap-1">
        {Array.from({ length: totalFullStars }).map((_, index) => (
          <StarIcon key={index} className="w-5 h-5" />
        ))}
        {hasHalfStar && <HalfStarIcon className="w-5 h-5" />}
        {Number(value) === 0 && (
          <>
            {Array.from({ length: 5 }).map((_, index) => (
              <EmptyStarIcon key={index} className="w-5 h-5" />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
