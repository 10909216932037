import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { getInitial, getUser, setUser } from "../../../services/utils";
import { Password } from "../components/Password";
import { TextInput } from "../../../components/TextInput";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import React from "react";
import passwordValidation from "../register/Register";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { ImageView } from "../../../components/Image";
import { fileUpload } from "../../../services/file-upload";
import { useUser } from "../../../services/user-context";

export function EditProfileModal({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const user = getUser();
  const { setUserInfo, userInfo } = useUser() as any;
  const [form, setForm] = useState<any>({ ...user, ...userInfo });
  const profileImgRef = useRef<HTMLInputElement | null>(null);
  const [profileImg, setProfileImg] = useState<any>(null);
  const [errors, setErrors] = React.useState({} as any);
  const [disabled, setDisabled] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload: any = {
      email: form.email,
      firstName: form.firstName,
      lastName: form.lastName,
      mobile: form.mobile,
      profileImg: form.profileImg,
    };
    try {
      setErrors({});
      setError("");
      setSaving(true);
      if (profileImg) {
        const frontSideResponse = await fileUpload(
          profileImg,
          user.id,
          "USER_PROFILE"
        );
        if (frontSideResponse.url) {
          payload.profileImg = frontSideResponse.url;
        }
      }
      const response = await api.saveUserProfile(user.id, payload);
      setSaving(false);
      const result = response;
      if ([200, 201].includes(result.status)) {
        ShowToast({
          type: "success",
          message: "Profile Updated!",
        });
        const userResponse = await api.getUserProfile(user.id);
        if (userResponse) {
          setUserInfo && setUserInfo(userResponse);
          setUser(userResponse);
          onClose();
        }
      }
    } catch (error: any) {
      setSaving(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError(error?.response?.data?.message || "Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    console.log(e.target.name);
    if (data.email && data.firstName && data.lastName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const url = profileImg ? URL.createObjectURL(profileImg) : form.profileImg;
  console.log(form);
  return (
    <Modal
      contentClassName="!px-0"
      title="Edit Profile"
      isOpen={isOpen}
      handleModal={onClose}
    >
      <div className="max-h-[calc(100vh-125px)] overflow-auto">
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <div className="flex mt-[26px] px-5  flex-col gap-4">
            {error && (
              <div className="text-red-500 text-xs font-medium mb-2 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <div className="self-stretch h-24 bg-white rounded-xl flex-col justify-center items-start gap-3.5 flex">
              <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
                <div className="justify-start items-center gap-3.5 flex">
                  <input
                    type="file"
                    onChange={(e) => {
                      if (e.target.files?.[0]) {
                        setProfileImg(e.target.files[0]);
                      }
                    }}
                    id="profileImg"
                    className="hidden"
                    accept="image/png, image/jpeg"
                    ref={(e) => (profileImgRef.current = e)}
                  />
                  {url ? (
                    <ImageView
                      className="!w-24 !h-24 rounded-full object-cover"
                      src={url}
                      alt="user"
                      loading="lazy"
                    />
                  ) : (
                    <div className="uppercase w-24 h-24 rounded-[10px] flex justify-center items-center text-3xl text-white bg-primary">
                      {getInitial(user?.firstName, user?.lastName)}
                    </div>
                  )}
                  <div className="flex flex-col gap-[14px]">
                    <button
                      onClick={() => profileImgRef.current?.click()}
                      className="text-primary text-base font-medium"
                      type="button"
                    >
                      Change photo
                    </button>
                    {(form.profileImg || profileImg) && (
                      <button
                        onClick={() => {
                          setProfileImg(null);
                          setForm((prev) => ({ ...prev, profileImg: "" }));
                        }}
                        className="text-[#D12E2E] text-base font-medium"
                        type="button"
                      >
                        Remove photo
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex-1 flex flex-col gap-1.5">
                <label htmlFor="email" className="text-sm text-black/80">
                  First Name
                </label>
                <TextInput
                  placeholder="Enter first name"
                  type="text"
                  name="firstName"
                  maxLength={128}
                  value={form.firstName}
                />
              </div>
              <div className="flex-1 flex flex-col gap-1.5">
                <label htmlFor="email" className="text-sm text-black/80">
                  Last Name
                </label>
                <TextInput
                  placeholder="Enter last name"
                  type="text"
                  name="lastName"
                  maxLength={128}
                  value={form.lastName}
                />
              </div>
            </div>
            <div className="mb-4 flex flex-col gap-1.5">
              <label htmlFor="email" className="text-sm text-black/80">
                Email
              </label>
              <TextInput
                placeholder="Enter your email address"
                type="email"
                name="email"
                value={form.email}
              />
            </div>
            <div>
              <Button
                variant="primary"
                className="px-7 !py-4 !text-lg md:w-[180px] !font-medium !rounded-full"
                loading={saving}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
