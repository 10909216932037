export function CoachingDetails() {
  return (
    <div className="flex-[2] bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px]">
      <div className="font-semibold text-lg text-black mb-5">
        Field Coaching Details
      </div>
      <div className="flex gap-3 w-full flex-col md:flex-row">
        <div className="flex-1 rounded-[13px] border border-[#DDD] p-4 flex flex-col gap-[14px]">
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">FCR #:</span>
            <span className="text-black text-sm text-right">FCR-02313</span>
          </div>
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">Employee:</span>
            <span className="text-black text-sm text-right">
              Sophie Reynolds
            </span>
          </div>
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">Manager:</span>
            <span className="text-black text-sm text-right">
              Oliver Wiseman
            </span>
          </div>
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">Territory:</span>
            <span className="text-black text-sm text-right">Houston</span>
          </div>
        </div>
        <div className="flex-1 rounded-[13px] border border-[#DDD] p-4 flex flex-col gap-[14px]">
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">FCR #:</span>
            <span className="text-black text-sm text-right">FCR-02313</span>
          </div>
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">Employee:</span>
            <span className="text-black text-sm text-right">
              Sophie Reynolds
            </span>
          </div>
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">Manager:</span>
            <span className="text-black text-sm text-right">
              Oliver Wiseman
            </span>
          </div>
          <div className="flex justify-between gap-2">
            <span className="text-sm text-[#242424]">Territory:</span>
            <span className="text-black text-sm text-right">Houston</span>
          </div>
        </div>
      </div>
    </div>
  );
}
