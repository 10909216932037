import React, { useEffect, useState } from "react";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";

import { twMerge } from "tailwind-merge";
interface Props {
  className?: string;
  textClassName?: string;
  isStem?: boolean;
}
const ClockTimer = ({
  className = "",
  textClassName = "",
  isStem = false,
}: Props) => {
  const [timeSpent, setTimeSpent] = useState(0);
  const intervalRef = React.useRef<any>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeSpent((prevTimeLeft) => prevTimeLeft + 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const minutes = Math.floor(timeSpent / 60);
  const seconds = timeSpent % 60;

  return (
    <div
      className={twMerge(
        `flex items-center gap-2 fixed top-[18px] right-16 z-10 bg-primary px-2.5 py-1 rounded-full pr-[18px]`,
        className
      )}
    >
      <Clock className="[&_path]:stroke-white" />
      <span
        className={twMerge("text-lg font-medium text-white", textClassName)}
      >
        {minutes < 10 ? "0" : ""}
        {minutes}:{seconds < 10 ? "0" : ""}
        {seconds}
      </span>
    </div>
  );
};

export default React.memo(ClockTimer);
