export function Productivity() {
  return (
    <div className="flex-1 bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px]">
      <div className="font-semibold text-lg text-black mb-5">Productivity</div>
      <div className="flex gap-3 w-full">
        <div className="flex-1 rounded-[13px] border border-[#DDD] p-4 flex flex-col gap-[14px]">
          <div className="flex justify-between gap-6">
            <span className="text-sm text-[#242424]">
              Total Target Calls Per Day (Goal 7)
            </span>
            <span className="text-black text-sm text-right">7</span>
          </div>
          <div className="flex justify-between gap-6">
            <span className="text-sm text-[#242424]">
              Total HCP Calls Per Day (Goal 8)
            </span>
            <span className="text-black text-sm text-right">7</span>
          </div>
          <div className="flex justify-between gap-6">
            <span className="text-sm text-[#242424]">Target HCP/HCO Reach</span>
            <span className="text-black text-sm text-right">85%</span>
          </div>
        </div>
      </div>
    </div>
  );
}
