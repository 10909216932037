import { ReportPanelView } from "./ReportPanelView";

function Row({ number, text }: { number: number; text: string }) {
  return (
    <div className="justify-start items-start gap-1 flex">
      <div className="text-[#242424] text-sm h-9">{number}. </div>
      <div className="justify-between items-start gap-5 flex w-full">
        <div className="text-[#242424] text-sm">{text}</div>
        <div className="justify-start items-center gap-1 flex">
          <div className="px-5 py-2 bg-emerald-200 rounded-3xl justify-start items-center gap-2.5 flex">
            <div className="text-black text-sm">Yes</div>
          </div>
          <div className="px-5 py-2 bg-zinc-100 rounded-3xl justify-start items-center gap-2.5 flex">
            <div className="text-black text-sm">No</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ObservationCompliance() {
  return (
    <ReportPanelView title="Observation - Compliance" subTitle="">
      <div className="flex flex-col gap-3">
        <Row
          number={1}
          text="Product information was presented in a fair and balanced manner, consistent with approved labeling"
        />
        <Row
          number={2}
          text="Current, approved, unaltered promotionalmaterials were used while detailing HCPs"
        />
        <Row
          number={3}
          text="Meal and/or educational items were provided and reported accurately"
        />
        <Row
          number={4}
          text="All conduct observed was in accordance with Company’s Code of Business Conduct & Ethics and applicable Company policies and procedures"
        />
        <Row
          number={5}
          text="If Replied “No” to above question(s), please give details "
        />
      </div>
    </ReportPanelView>
  );
}
