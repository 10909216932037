import Slider from "react-rangeslider";

// To include the default styles
import "react-rangeslider/lib/index.css";

const horizontalLabels = {
  0: "Low",
  50: "Medium",
  100: "High",
};

const formatValue = (value: string) => {
  let val = 0;
  switch (value) {
    case "L":
      val = 0;
      break;
    case "M":
      val = 50;
      break;
    case "H":
      val = 100;
      break;
  }
  return val;
};

export function SliderView({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  // return <RangeSlider />;
  //   const format;
  return (
    <Slider
      min={0}
      max={100}
      step={50}
      value={formatValue(value)}
      labels={horizontalLabels}
      tooltip={false}
      onChange={(e) => {
        let val = "L";
        if (e === 50) {
          val = "M";
        } else if (e === 100) {
          val = "H";
        }
        onChange(val);
        console.log(e);
      }}
    />
  );
}
