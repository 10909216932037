// import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { ForgotPasswordSuccess } from "./components/ForgotPasswordSuccess";
import { ShowToast } from "../../services/toast";
import { api } from "../../services/api";
import { Header } from "./components/Header";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";

export function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [success, setSuccess] = React.useState(false);

  const handleSendEmail = async () => {
    if (email) {
      try {
        setLoading(true);
        const response = await api.forgotPassword({
          email,
          resetPasswordUrl: `${window.location.origin}/reset-password`,
        });
        if ([200, 201].includes(response.status)) {
          setSuccess(true);
        }
        setLoading(false);
      } catch (error) {
        ShowToast({
          type: "error",
          message:
            "No account found with the email, Please recheck the email ID",
        });
        setLoading(false);
      }
    }
  };

  // check if email is valid using regex
  const disabled = !email || !email.match(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

  return (
    <div className="h-screen bg-background">
      {/* <Header showSignIn isProvider={userType === USER_TYPES.PROVIDER} /> */}
      <div className="h-[100vh] overflow-auto py-10 px-4 md:px-0">
        <div className="flex flex-col">
          <div className="flex justify-center items-center min-h-[calc(100vh-150px)]">
            {success ? (
              <ForgotPasswordSuccess email={email} />
            ) : (
              <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col mx-3">
                <Link
                  to={"/login"}
                  className="flex items-center gap-1.5 text-base text-primary"
                >
                  <LeftArrow />
                  Back
                </Link>
                <div className="text-dark1 text-[28px] font-semibold leading-loose">
                  Forgot password?
                </div>
                <span className="text-black/80 text-sm font-normal leading-normal ">
                  Please provide your registered email below, and we'll send you
                  a link to reset your password.
                </span>
                <div className="my-6 flex flex-col gap-5">
                  <div className="flex flex-col gap-1.5">
                    <span className="text-black text-xs font-medium">
                      Email
                    </span>
                    <TextInput
                      placeholder="Your email"
                      className="w-full !p-[14px]"
                      name="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <Button
                  disabled={disabled}
                  loading={loading}
                  className="!rounded-full w-full"
                  variant="primary"
                  onClick={handleSendEmail}
                >
                  Submit
                </Button>

                <div className="flex items-center gap-1 justify-center mt-6 text-sm">
                  <span className="text-black font-medium">
                    Don't have an account?
                  </span>
                  <a href="/" className="text-primary/80 font-bold">
                    Create Account
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
