import { ReactComponent as ChevronDown } from "../assets/icons/chevron-left.svg";
import { getInitial } from "../services/utils";
import { ImageView } from "./Image";

export function UserButton({
  user,
  variant,
  className = "",
  onClick,
}: {
  user: any;
  variant?: string;
  className?: string;
  onClick: () => void;
}) {
  if (variant === "sideMenu") {
    return (
      <div
        role="button"
        onClick={onClick}
        className={`mb-4 cursor-pointer justify-start items-center gap-2 flex ${className}`}
      >
        <div className="flex-1 mx-4 p-3 bg-white rounded-md border border-neutral-200 items-center gap-3 flex">
          {user?.profileImg ? (
            <ImageView
              className="!w-9 !h-9 rounded-full object-cover"
              src={user?.profileImg}
              alt="user"
              loading="lazy"
            />
          ) : (
            <div className="uppercase !w-9 !h-9 rounded-full flex justify-center items-center text-base font-medium text-white bg-primary">
              {getInitial(user?.firstName, user?.lastName)}
            </div>
          )}
          <div className="flex-1 justify-between items-center gap-px flex">
            <div className="text-[#060606] text-lg font-medium">
              {user.firstName} {user.lastName}
            </div>
            <ChevronDown className="w-5 h-5 rotate-90" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      role="button"
      onClick={() => onClick()}
      className={`cursor-pointer justify-start items-center gap-2 flex ${className}`}
    >
      <div className="pl-1.5 pr-3.5 py-1.5 bg-white rounded-3xl border border-neutral-200 justify-center items-center gap-1 flex">
        {user?.profileImg ? (
          <ImageView
            className="w-9 h-9 rounded-full object-cover"
            src={user?.profileImg}
            alt="user"
            loading="lazy"
          />
        ) : (
          <div className="uppercase w-9 h-9 rounded-full flex justify-center items-center text-base font-medium text-primary bg-secondary">
            {getInitial(user?.firstName, user?.lastName)}
          </div>
        )}
        <div className="justify-center items-center gap-px flex">
          <div className="text-[#060606] text-base font-medium">
            {user?.firstName}
          </div>
          <ChevronDown className="w-3.5 h-3.5 relative" />
        </div>
      </div>
    </div>
  );
}
