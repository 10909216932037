import { useState } from "react";
import { Button } from "../../components/Button";
import { usePraxisCall } from "../praxis-play/context/praxiscall-context";
import { FullRecord } from "./FullRecord";
import { AIOverview } from "./components/AIOverview";
import { PerformanceView } from "./components/PerformanceView";
import { ReportOverview } from "./components/ReportOverview";
import { TranscriptView } from "./components/TranscriptView";
import { FCRReport } from "./FCRReport";

export function ReportsView() {
  const { setCallStatus, report, reportLoading } = usePraxisCall() as any;
  const [showFcrReport, setShowFcrReport] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  return (
    <div className="bg-[#F3F1F2] p-4 md:p-8">
      {!showFcrReport ? (
        <>
          <div className="mb-8 flex items-center justify-between">
            <div>
              <div className="text-black text-2xl font-bold">
                Praxis Play Feedback
              </div>
              <div className="text-black text-lg mt-3">
                View your AI generated feedback for you last Praxis Play session
              </div>
            </div>
            <Button
              variant="primary"
              className="!rounded-full !py-3 !px-10"
              onClick={() => setCallStatus("idle")}
            >
              Call Again
            </Button>
          </div>
          <div className="mb-5">
            <ReportOverview
              report={report}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </div>
          <div className="flex flex-col lg:flex-row items-start gap-5 mb-5">
            <div className="flex-1 w-full">
              <AIOverview report={report} />
            </div>
            <div className="flex-1 w-full">
              <TranscriptView
                conversations={report?.conversations || 0}
                currentIndex={currentIndex}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start gap-5">
            <div className="flex-1 w-full">
              <div className="bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px] flex flex-col gap-6">
                <PerformanceView
                  title="Language Clarity and Precision"
                  value={report?.languageClarity || 0}
                />
                <PerformanceView
                  title="Vocabulary and Terminology"
                  value={report?.vocabulary || 0}
                />
                <PerformanceView
                  title="Brand Strategy Alignment"
                  value={report?.brandStrategy || 0}
                />
                {/** subject to change, currently using other variable. */}
                {/* <PerformanceView
                  title="Tone and Diction"
                  value={report?.tone || 0}
                /> */}
              </div>
            </div>
            <div className="flex-1 w-full">
              <div className="bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px] flex flex-col gap-6">
                {/* <PerformanceView
                  title="Response Time"
                  value={report?.responseTime}
                /> */}
                <PerformanceView
                  title="Questioning Techniques"
                  value={report?.questionTechniques || 0}
                />
                <PerformanceView
                  title="Compliance"
                  value={report?.compliancePercentage || 0}
                />
                <PerformanceView title="Tone" value={report?.tone} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <FCRReport />
      )}
    </div>
  );
}
