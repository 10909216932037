export function CallProcessingLoadingState() {
  return (
    <div className="fixed z-[4] bg-black top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center gap-2">
      <h1 className="text-[42px] text-white/80 font-bold">Praxis Play</h1>
      <div className="max-w-[460px] mx-auto flex">
        <span className="text-[22px] text-center font-light text-white/80">
          Processing Call Recording & Generating Reports.{" "}
        </span>
      </div>
    </div>
  );
}
