import { motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";
import { useEffect } from "react";

export default function Modal({
  children,
  isOpen,
  handleModal,
  title,
  secondaryTitle,
  customTitle,
  zIndex,
  className,
  width,
  noTitle,
  contentClassName,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  handleModal?: () => void;
  title?: string;
  secondaryTitle?: React.ReactNode;
  customTitle?: React.ReactNode;
  zIndex?: string;
  className?: string;
  width?: string;
  noTitle?: boolean;
  contentClassName?: string;
}) {
  // stop scroll when modal is open
  const enableScroll = () => {
    document.body.style.overflow = "";
  };
  const disabledScroll = () => {
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    if (isOpen) {
      disabledScroll();
    } else {
      enableScroll();
    }
    return () => {
      enableScroll();
    };
  }, [isOpen]);

  return (
    <motion.div
      animate={{
        opacity: isOpen ? 1 : 0,
        display: isOpen ? "block" : "none",
      }}
      exit={{ display: "none" }}
      transition={{ delay: 0 }}
      className={className}
    >
      <motion.div
        className={`w-full h-[100vh] fixed top-0 left-0 z-[9999] overflow-auto flex justify-center items-center`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.25 }}
      >
        <div
          className={`bg-white w-[675px] p-5 rounded-[14px] mx-2 md:mx-auto ${contentClassName}`}
        >
          {title && (
            <div className="flex items-center justify-between px-5">
              <h1 className="text-xl font-bold">{title}</h1>
              <button onClick={handleModal}>
                <CloseIcon className="w-6 h-6" />
              </button>
            </div>
          )}
          <div>{children}</div>
        </div>
        {/* all over behind dropdown click closes the dropdown */}
      </motion.div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[100] h-[100vh] opacity-[0.3] bg-[black]"
          onClick={handleModal}
        />
      )}
    </motion.div>
  );
}
