import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { setToken, setUser as setUserInfo } from "../../../services/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { Password } from "../components/Password";
import passwordValidation from "../register/Register";
import { ChangePassword } from "../ChangePassword";

export function Login() {
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState({} as any);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [showChangePassword, setShowChangePassword] = useState<any>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setError("");
    setLoading(true);
    try {
      const response = await api.login({
        email: data.email,
        password: data.password,
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      console.log(response);
      const result = response.data;
      if (result?.accessToken) {
        setToken(result?.accessToken);
        console.log(result);
        if (result?.id) {
          const userResponse = await api.getUserProfile(result?.id);
          console.log(userResponse);
          setUserInfo({ ...userResponse });
        }

        if (result?.loginState === "Change Password") {
          setShowChangePassword(result);
        } else {
          navigate("/app/praxisplay");
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password: "Invalid password",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      data.email &&
      data.password &&
      passwordValidation(data.password.toString())
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleChangePasswordClose = (success?: boolean) => {
    setShowChangePassword(false);
    if (success) {
      navigate("/app/praxisplay");
    }
  };

  return (
    <div className="h-screen bg-background">
      <div className="h-[100vh] overflow-auto py-10 px-4 md:px-0">
        <div className="flex justify-center items-center min-h-[calc(100vh-75px)]">
          <div className="w-[442px]">
            <div className="bg-white p-8 rounded-[16px]">
              <h1 className="text-3xl font-bold">Sign In</h1>
              <div className="flex items-center gap-1 mt-3 text-sm md:text-lg">
                <span className="text-black">Don't have an account?</span>
                <a href="/" className="text-primaryLink font-semibold">
                  Create Account
                </a>
              </div>
              {error && (
                <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                  <AlertIcon /> {error}
                </div>
              )}
              <form
                className="mt-8"
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                <div className="mb-8 flex flex-col gap-1.5">
                  <label htmlFor="email" className="text-sm text-black/80">
                    Email
                  </label>
                  <TextInput
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                  />
                </div>
                <div className=" flex flex-col gap-1.5">
                  <label htmlFor="password" className="text-sm text-black/80">
                    Password
                  </label>
                  <Password
                    type="password"
                    name="password"
                    placeholder="Enter a password"
                  />
                  {errors.password && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="flex justify-end mt-[14px]">
                  <a
                    href="/forgot-password"
                    className="text-black/80 text-sm font-medium"
                  >
                    Forgot password?
                  </a>
                </div>
                <div className="mt-7">
                  <Button
                    type="submit"
                    variant="primary"
                    className="!rounded-full w-full"
                    disabled={disabled || loading}
                    loading={loading}
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showChangePassword && (
        <ChangePassword
          isOpen={!!showChangePassword}
          onClose={handleChangePasswordClose}
          user={showChangePassword}
          fromLogin={true}
        />
      )}
    </div>
  );
}
