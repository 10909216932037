import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";

import { ReactComponent as DesktopIcon } from "../../assets/icons/desktop.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";

// import userImage from "../../assets/icons/user.png";

import { MANAGE_STRIPE_URL } from "../../env";
import { getInitial } from "../../services/utils";
import { ImageView } from "../Image";

export const menuItems = [
  // {
  //   title: "Dashboard",
  //   Icon: DashboardIcon,
  //   path: "/dashboard",
  // },
  {
    title: "PraxisPlay",
    Icon: DesktopIcon,
    path: "/app/praxisplay",
  },
];

export function Sidebar({ user, onEdit }: { user: any; onEdit: () => void }) {
  const location = useLocation();
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
  };
  return (
    <>
      <div className="hidden lg:flex max-h-[100vh] overflow-auto bg-[#7C2928] border-r border-gray-200 flex-col">
        <div className="self-stretch px-12 py-8 border-b border-white/20 justify-between items-center flex">
          <div className="justify-center items-center flex">
            <Logo />
          </div>
        </div>
        <div className="flex h-[calc(100vh-80px)] justify-between flex-col">
          <div className="flex-col justify-start items-start gap-5 flex">
            <div className="flex-col justify-start items-start flex w-full p-4 gap-2">
              {menuItems.map((item, index) => (
                <Link
                  to={item.path}
                  key={index}
                  className={`w-full px-6 py-5 rounded-[12px] justify-start items-center gap-3 flex group hover:bg-white/[0.08] transition duration-75 hover:scale-[1.02] ${
                    isSelected(item.path) ? "!bg-white/[0.17]" : ""
                  } `}
                >
                  <item.Icon />

                  <div className="grow shrink basis-0 text-white text-sm">
                    {item.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="mx-4 mb-4 p-5 bg-black bg-opacity-20 rounded-2xl border border-white border-opacity-20 flex-col justify-start items-center gap-3 inline-flex">
            <div className="w-16 h-16">
              {user?.profileImg ? (
                <ImageView
                  className="w-full h-full rounded-full object-cover"
                  src={user?.profileImg}
                  alt="user"
                />
              ) : (
                <div className="bg-primary w-full h-full rounded-full text-white text-3xl flex justify-center items-center font-semibold">
                  {getInitial(user?.firstName, user?.lastName)}
                </div>
              )}
            </div>
            <div
              className="text-center text-white text-base font-bold max-w-[225px] overflow-hidden text-ellipsis whitespace-nowrap"
              title={`${user.firstName} ${user.lastName}`}
            >
              {user.firstName} {user.lastName}
            </div>
            <div className="justify-start items-center gap-3.5 inline-flex">
              <div className="justify-start items-center gap-1 group flex">
                <Pencil className="w-5 h-5 relative group-hover:[&_path]:stroke-indigo-600" />
                <button
                  onClick={onEdit}
                  className="text-center group-hover:text-indigo-600 text-indigo-200 text-base font-normal"
                >
                  Edit
                </button>
              </div>
              <div className="border-l border-[#C4BEDC] h-[80%]"></div>
              <div className="justify-start group items-center gap-1 flex">
                <Logout className="w-5 h-5 relative group-hover:[&_path]:stroke-red-600" />
                <button
                  onClick={handleLogout}
                  className="text-center group-hover:text-red-600 text-red-300 text-base font-normal"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
