import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.25 } },
  transition: { duration: 0.5 },
};

export function RenderSmoothImage({
  src,
  alt,
  className = "",
  imageClassName = "",
  imageProps = {},
}: {
  src: string;
  alt: string;
  className?: string;
  imageClassName?: string;
  imageProps?: any;
}) {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [isValidSrc, setIsValidSrc] = React.useState(!!src);
  return (
    <div className={`smooth-image-wrapper w-full h-full ${className}`}>
      {isValidSrc ? (
        <img
          src={src}
          alt={alt}
          className={`smooth-image ${imageClassName} ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
          onLoad={() => setImageLoaded(true)}
          onError={() => setIsValidSrc(false)}
          {...imageProps}
        />
      ) : (
        <div className="smooth-image image-error">
          <span>Image not available</span>
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {!imageLoaded && (
          <motion.div {...framerSidebarBackground} className="smooth-preloader">
            <span {...imageProps} className={`loader ${imageClassName}`} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
