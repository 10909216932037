import React, { useEffect } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import useRecorder from "../../../services/useRecorder";
import { CallProcessingLoadingState } from "../../praxis-call/components/CallProcessingLoadingState";

export const PraxisCallContext = React.createContext({});

const displayMediaOptions = {
  video: {
    displaySurface: "browser",
  },
  audio: {
    suppressLocalAudioPlayback: false,
  },
  preferCurrentTab: true,
  selfBrowserSurface: "exclude",
  systemAudio: "include",
  surfaceSwitching: "include",
  monitorTypeSurfaces: "include",
};

export function PraxisCallProvider({ children }) {
  const user = getUser();
  const [callDetails, setCallDetails] = React.useState<any>({});
  const [isCharacterSpeaking, setIsCharacterSpeaking] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [callStatus, setCallStatus] = React.useState("idle"); // idle, calling, connected, ended
  const [report, setReport] = React.useState<any>({});
  const [fetchingReports, setFetchingReports] = React.useState(false);
  const [recordingChunks, setRecordingChunks] = React.useState<any>([]);
  const deviceRecorder = React.useRef<any>();
  // const { startRecording, stopRecording, audioBlob } = useRecorder();

  const fetchReports = async () => {
    try {
      setFetchingReports(true);
      const response = await api.getReports(callDetails?.id);
      setFetchingReports(false);
      if ([200, 201].includes(response.status)) {
        console.log("reports", response.data);
        setReport(response.data);
      }
    } catch (error: any) {
      setFetchingReports(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while fetching the reports",
        type: "error",
      });
    }
  };

  const onDisconnect = async () => {
    try {
      // const videoBlob = stopRecording();
      // stopRecording();
      setLoading(true);
      const response = await api.endCall(callDetails?.id);

      if ([200, 201].includes(response.status)) {
        await fetchReports();
        setLoading(false);
        setCallStatus("ended");
      } else {
        ShowToast({
          message: response?.data?.message || "An error occurred",
          type: "error",
        });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while ending the call",
        type: "error",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (callStatus === "calling") {
      // startRecording();
    }
  }, [callStatus]);

  const values = {
    callDetails,
    setCallDetails,
    callStatus,
    report,
    setCallStatus,
    onDisconnect,
  };
  return (
    <PraxisCallContext.Provider value={values}>
      {loading ? <CallProcessingLoadingState /> : children}
    </PraxisCallContext.Provider>
  );
}

export function usePraxisCall() {
  const context = React.useContext(PraxisCallContext);
  if (context === undefined) {
    throw new Error("usePraxisCall must be used within a PraxisCallProvider");
  }
  return context;
}
