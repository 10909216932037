import { Button } from "../../components/Button";
import { ReactComponent as LeftArrow } from "../../assets/icons/chevron-left.svg";
import { CustomizerModal } from "./CustomizerModal";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { ResponsiveCarousel } from "./components/Carousel";
import { ShowToast } from "../../services/toast";
import { api } from "../../services/api";
import { PraxisLoading } from "../praxis-call/PraxisLoading";
import { PraxisCall } from "../praxis-call/PraxisCall";
import { usePraxisCall } from "./context/praxiscall-context";
import { getUser } from "../../services/utils";
import { ReportsView } from "../praxis-call/ReportScreen";
import { CallProcessingLoadingState } from "../praxis-call/components/CallProcessingLoadingState";
import { speak } from "../praxis-call/utils";

export function PraxisPlay() {
  const user = getUser();
  const { setCallDetails, callStatus, setCallStatus } = usePraxisCall() as any;
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCustomizer, setShowCustomizer] = useState(false);
  const [characters, setCharacters] = useState<any[]>([]);
  const [form, setForm] = useState({
    practitionerType: "Internal Medicine",
    objectionLevel: "M",
    personalityType: [],
    beginTime: 0,
    isPatientBased: true,
    imgUrl: "character1",
  });

  const handleCustomizerContinue = (customizeForm: any) => {
    console.log(customizeForm);
    setForm((prev) => ({
      ...prev,
      ...customizeForm,
    }));
    setShowCustomizer(false);
  };

  const handleBegin = async () => {
    console.log(form);
    try {
      setSaving(true);
      // save form data
      speak("");
      const response = await api.startCall({ ...form, userId: user?.id });
      console.log(response);
      setSaving(false);
      if ([200, 201].includes(response.status)) {
        setCallDetails(response.data);
        // ShowToast({
        //   message: "Character created successfully",
        //   type: "success",
        // });
        setCallStatus("calling");
      }
    } catch (error: any) {
      setSaving(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while saving your data",
        type: "error",
      });
      console.log(error);
    }
  };

  const handleCharacterChange = (character: any) => {
    console.log(character);
    // set img url in form
  };

  useEffect(() => {
    setLoading(true);
    api
      .getCharacters()
      .then((res) => {
        setLoading(false);
        console.log(res);
        const characters = res?.data?.filter((c: any) => c.isActive) || [];
        setCharacters([...characters]);
        const personalityTypes = ["Skeptical", "Busy", "Clinical data driven"];
        if (
          characters[0]?.personalityType &&
          characters[0]?.personalityType?.split(",")?.length > 0
        ) {
          personalityTypes.push(...characters[0]?.personalityType?.split(","));
        }
        setForm({
          ...(characters[0] || {}),
          personalityType: personalityTypes,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  if (callStatus === "ended") {
    return <ReportsView />;
  }

  if (callStatus !== "idle") {
    return <PraxisCall />;
  }

  if (saving) {
    return <PraxisLoading />;
  }

  return (
    <div className="p-4 md:p-6 !pt-[80px] pb-8 lg:!pt-4 md:flex md:flex-col md:h-[100vh] lg:h-full">
      <div className="flex-col justify-start items-start tall2:gap-2 gap-2 md:gap-4 inline-flex">
        <div className="text-black text-2xl font-bold">Praxis Play</div>
        <div className="text-black text-sm md:text-lg font-normal">
          Sharpen your product knowledge and skills with interactive sessions
          with an AI-generated character.
        </div>
      </div>
      <div className="md:self-center md:flex-1 tall2:h-[calc(100vh-118px)] h-auto overflow-auto w-full tall2:mt-3 mt-4 md:mt-7 px-6 py-6 bg-white rounded-2xl shadow border flex-col justify-start items-start tall2:gap-2 gap-8 flex">
        {/* <div className="justify-start items-center gap-0.5 flex">
          <LeftArrow className="w-5 h-5 relative" />
          <div className="text-black text-base font-normal ">Back</div>
        </div> */}
        <div className="w-full flex-1 flex-col justify-start items-center tall2:gap-5 gap-7 md:gap-12 flex">
          <div className="self-stretch text-center text-neutral-800 text-[15px] md:text-lg font-normal">
            Select a HCP character to practice with or <br />
            use the customizer to create your own HCP character.
          </div>
          <div className="flex-1 flex-col justify-start items-center tall2:gap-3 gap-7 flex w-full">
            <div className="slider-container flex justify-center items-center h-full flex-1 w-full">
              <ResponsiveCarousel
                onChange={handleCharacterChange}
                data={characters.map((c: any) => ({
                  id: c.id,
                  // cover: c.imgUrl,
                  cover: "/images/character.svg",
                }))}
                isLoading={loading}
              />
            </div>
            <div className="self-stretch flex-col justify-start items-center gap-0.5 flex">
              <div className="self-stretch text-center text-neutral-800 text-base md:text-lg font-bold">
                Dr. Erin Jones
              </div>
              <div className="self-stretch text-center text-neutral-800 text-sm md:text-base font-normal ">
                {form.practitionerType}
              </div>
              <div className="self-stretch text-center">
                <span className="text-neutral-800 text-base font-medium ">
                  Personality type:
                </span>
                <span className="text-neutral-800 text-sm md:text-base font-normal ">
                  {" "}
                  {form?.personalityType && form?.personalityType?.join(", ")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full justify-between items-start flex flex-col md:flex-row gap-2">
          <Button
            variant="primary"
            className="w-full md:w-auto px-7 !py-4 !bg-[#F8E9F1] hover:!bg-[#C83E1E] hover:!text-white !rounded-full !text-lg !font-medium !text-[#7C2928]"
            onClick={() => setShowCustomizer(true)}
          >
            Customizer
          </Button>
          <Button
            variant="primary"
            className="w-full md:w-auto px-7 !py-4 !rounded-full !text-lg !font-medium"
            onClick={handleBegin}
            loading={saving}
            disabled={saving}
          >
            Begin Praxis Play
          </Button>
        </div>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {showCustomizer && (
          <CustomizerModal
            data={form}
            isOpen={showCustomizer}
            onClose={() => setShowCustomizer(false)}
            onContinue={handleCustomizerContinue}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
