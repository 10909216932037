import { Loading } from "../Loading/Loading";

type Props = {
  variant: "primary" | "outline" | "link";
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export function Button({ variant, className, loading, ...props }: Props) {
  return (
    <button
      className={`transition duration-75 rounded-md text-sm text-white font-medium leading-normal py-5 px-5 flex gap-1.5 items-center justify-center
      ${variant === "outline" ? "border border-primary !text-primary" : ""}
      ${variant === "primary" ? "bg-primary" : ""}
      ${variant === "link" ? "!p-0" : ""}
      ${
        props.disabled || loading
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-[#C83E1E]"
      }
      ${className || ""}
    `}
      {...props}
    >
      {props.children}
      {loading && <Loading />}
    </button>
  );
}
