export class AudioPlayer {
  constructor() {
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    this.queue = [];
    this.isPlaying = false;
    this.bufferDuration = 0.2; // Duration of each buffer
    this.crossfadeDuration = 0.05; // Duration of crossfade between buffers
    this.initialBufferCount = 0; // Number of buffers to accumulate before starting playback
    this.lastEndTime = 0; // Track the end time of the last played buffer
    // Create custom events for playback started and finished
    this.onPlaybackStarted = new Event("playbackStarted");
    this.onPlaybackFinished = new Event("playbackFinished");

    this.analyser = this.audioContext.createAnalyser(); // Step 1
    this.analyser.fftSize = 2048; // Step 2: Example size, adjust as needed
    this.dataArray = new Float32Array(this.analyser.frequencyBinCount); // For storing audio data
    this.volumeMonitorInterval = null; // Store the interval ID
  }

  enqueueBuffer(arrayBuffer) {
    // Copy the ArrayBuffer to ensure it is not detached
    const bufferCopy = arrayBuffer.slice(0);
    this.queue.push(bufferCopy);
    if (!this.isPlaying && this.queue.length >= this.initialBufferCount) {
      this.isPlaying = true;
      this.playNext();
    }
  }

  playNext() {
    if (this.queue.length === 0) {
      this.isPlaying = false;
      return;
    }
    // === start monitoring vol
    this.startMonitoringVolume();

    const arrayBuffer = this.queue.shift();

    try {
      this.audioContext.decodeAudioData(
        arrayBuffer,
        (buffer) => {
          const source = this.audioContext.createBufferSource();
          source.buffer = buffer;
          source.connect(this.audioContext.destination);

          // new
          source.connect(this.analyser); // Connect source to analyser
          this.analyser.connect(this.audioContext.destination); // Connect analyser to destination
          // new

          const currentTime = this.audioContext.currentTime;
          const startTime = Math.max(this.lastEndTime, currentTime);
          source.start(startTime);

          // Dispatch the playback started event
          this.audioContext.dispatchEvent(this.onPlaybackStarted);

          // Schedule the next buffer to start slightly before this one ends
          this.lastEndTime = startTime + buffer.duration;
          setTimeout(
            () => this.playNext(),
            (buffer.duration - this.crossfadeDuration) * 1000
          );

          source.onended = () => {
            if (this.queue.length === 0) {
              // ======= stop monitoring vol
              this.stopMonitoringVolume();
              this.isPlaying = false;
              // Dispatch the playback finished event
              this.audioContext.dispatchEvent(this.onPlaybackFinished);
            }
          };
        },
        (error) => {
          console.log("Error decoding audio data", error);
          if (this.queue.length > 0) {
            this.playNext();
          } else {
            this.isPlaying = false;
          }
        }
      );
    } catch (error) {}
  }
  calculateVolume() {
    this.analyser.getFloatTimeDomainData(this.dataArray); // Step 4: Get audio data
    let sum = 0;
    for (let i = 0; i < this.dataArray.length; i++) {
      sum += this.dataArray[i] * this.dataArray[i]; // Step 5: Calculate RMS
    }
    let rms = Math.sqrt(sum / this.dataArray.length);
    const animationScaleValue = (rms + 1)?.toFixed(3);
    // console.log("animationScaleValue", animationScaleValue);
    document.documentElement.style.setProperty(
      "--voice-speaking-intencity",
      `${animationScaleValue}`
    );
    return rms;
  }
  startMonitoringVolume() {
    if (this.volumeMonitorInterval !== null) {
      clearInterval(this.volumeMonitorInterval); // Clear existing interval if any
    }
    this.volumeMonitorInterval = setInterval(() => {
      const volume = this.calculateVolume();
      // console.log(`Volume: ${volume}`);
    }, 100); // Log volume every 100ms
  }

  stopMonitoringVolume() {
    if (this.volumeMonitorInterval !== null) {
      clearInterval(this.volumeMonitorInterval);
      this.volumeMonitorInterval = null;
    }
  }
}
