import React, { useEffect, useMemo } from "react";
import {
  ResponsiveContainer,
  StackedCarousel,
} from "react-stacked-center-carousel";
import character1 from "../../../assets/characters/character1.png";
import character2 from "../../../assets/characters/character2.png";
import character3 from "../../../assets/characters/character3.png";
import character4 from "../../../assets/characters/character4.png";
import character5 from "../../../assets/characters/character5.png";
import { ReactComponent as PrevIcon } from "../../../assets/icons/carousel-left.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/carousel-right.svg";
import useResponsive from "../../../services/useResponsive";
import { RenderSmoothImage } from "../../../components/RenderSmoothImage";

const heightPoint = 350 / 950;
const widthPoint = 226 / 1440;

const dataLoading = [
  {
    isLoading: true,
    cover: character1,
  },
  {
    isLoading: true,
    cover: character1,
  },
  {
    isLoading: true,
    cover: character1,
  },
  {
    isLoading: true,
    cover: character1,
  },
  {
    isLoading: true,
    cover: character1,
  },
  {
    isLoading: true,
    cover: character1,
  },
  {
    isLoading: true,
    cover: character1,
  },
];

const calculateImageWidthAndHeight = (innerHeight, innerWidth) => {
  // for 1440 x 950 screen image width is 226px and height is 360px
  // based on this we can calculate the width and height for any screen size

  const height = heightPoint * innerHeight;
  const width = widthPoint * innerWidth;
  return { height, width };
};

export const Card = React.memo(function (props: any) {
  const { data, dataIndex } = props;
  const { cover, isLoading } = data[dataIndex];
  const [height, setHeight] = React.useState(heightPoint * window.innerHeight);
  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    window.addEventListener("resize", () => {
      const { width, height } = calculateImageWidthAndHeight(
        window.innerHeight,
        window.innerWidth
      );
      // console.log("width", width, "height", height);
      setHeight(height);
    });
    return () => {
      window.removeEventListener("resize", () => {
        const { width, height } = calculateImageWidthAndHeight(
          window.innerHeight,
          window.innerWidth
        );
        // console.log("width", width, "height", height);
        setHeight(height);
      });
    };
  }, [heightPoint]);

  // console.log(props);
  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "238px" : height + "px",
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      {isLoading ? (
        <div className="smooth-preloader">
          <span
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "12px",
            }}
            className={`loader`}
          />
        </div>
      ) : (
        <RenderSmoothImage
          imageProps={{
            style: {
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "12px",
              backgroundColor: "#F0ECEC",
            },
            draggable: false,
          }}
          src={cover}
          alt="character"
        />
      )}
      {/* <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "fill",
          borderRadius: "12px",
        }}
        draggable={false}
        src={cover}
        alt="character"
      /> */}
    </div>
  );
});

export function ResponsiveCarousel({
  onChange,
  data = [],
  isLoading,
}: {
  onChange: (index: number) => void;
  data: any[];
  isLoading: boolean;
}) {
  // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
  // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
  // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
  // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
  const ref = React.useRef<any>();
  const heightPoint = 360 / 950;
  const widthPoint = 226 / 1440;
  const [height, setHeight] = React.useState(heightPoint * window.innerHeight);
  const [width, setWidth] = React.useState(widthPoint * window.innerWidth);
  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    window.addEventListener("resize", () => {
      const { width, height } = calculateImageWidthAndHeight(
        window.innerHeight,
        window.innerWidth
      );
      // console.log("width", width, "height", height);
      setWidth(width);
      setHeight(height);
    });
    return () => {
      window.removeEventListener("resize", () => {
        const { width, height } = calculateImageWidthAndHeight(
          window.innerHeight,
          window.innerWidth
        );
        // console.log("width", width, "height", height);
        setWidth(width);
        setHeight(height);
      });
    };
  }, [heightPoint, widthPoint]);
  // console.log(data);
  if (!isLoading && data.length === 0) return null;
  console.log(isLoading, "isLoading");
  return (
    <>
      <button
        className="-mr-6 hover:opacity-70 z-[1]"
        onClick={() => ref.current?.goBack()}
      >
        <PrevIcon />
      </button>
      <div
        className="max-w-[350px] md:max-w-[550px] desktop3:max-w-[410px] desktop5:max-w-[550px] w-full relative"
        style={{
          width: "100%",
          position: "relative",
          maxHeight: isMobile ? "238px" : height + "px",
        }}
      >
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = isMobile ? 3 : isTablet ? 5 : 5;
            // console.log("parent width", parentWidth);
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                slideWidth={isMobile ? 150 : height / 1.6}
                carouselWidth={parentWidth}
                data={
                  isLoading
                    ? [...dataLoading]
                    : [
                        ...data,
                        ...data,
                        ...data,
                        ...data,
                        ...data,
                        ...data,
                        ...data,
                      ]
                }
                maxVisibleSlide={5}
                currentVisibleSlide={currentVisibleSlide}
                height={isMobile ? 238 : height}
                onActiveSlideChange={(index: number) => {
                  onChange(index);
                }}
              />
            );
          }}
        />
      </div>
      <button
        className="-ml-6 hover:opacity-70 z-[1]"
        onClick={() => ref.current?.goNext(data.length)}
      >
        <NextIcon />
      </button>
    </>
  );
}
