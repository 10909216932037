import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Routes from "./pages/routes";
import "./App.css";
import { useEffect } from "react";
import { useUser } from "./services/user-context";
import { api } from "./services/api";
import { getUser } from "./services/utils";

// Create a client
const queryClient = new QueryClient();

function App() {
  const user = getUser();
  useEffect(() => {
    return () => {
      speechSynthesis.cancel();
    };
  }, []);

  const makeLastActive = async (userId: string) => {
    try {
      await api.saveLastActive(userId);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      if (user?.id) {
        makeLastActive(user?.id);
      }
    } catch (error) {}
    return () => {
      try {
        if (user?.id) {
          makeLastActive(user?.id);
        }
      } catch (error) {}
    };
  }, [user?.id]);

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <div className="min-h-screen flex flex-col">
        <Routes />
      </div>
    </QueryClientProvider>
  );
}

export default App;
