import { motion } from "framer-motion";

export function Dropdown({
  isOpen,
  setIsOpen,
  className = "",
  children,
  padding,
  fromTop = true,
  noJustify,
}: {
  isOpen: boolean;
  setIsOpen: () => void;
  className?: string;
  children: React.ReactNode;
  padding: string;
  fromTop?: boolean;
  noJustify?: boolean;
}) {
  return (
    <>
      <motion.div
        className={`${className} absolute top-[2rem] right-0  bg-[white] rounded-[10px] p-2 z-[101] shadow-[0px_6px_24px_0px_rgba(17,85,52,0.25)] min-w-[100px] h-auto p-[1.5rem] overflow-hidden`}
        initial={{ maxHeight: "0px", padding: "0px" }}
        animate={{
          maxHeight: isOpen ? "500px" : "0px",
          padding: isOpen ? padding : "0px",
        }}
        exit={{ maxHeight: "0px", padding: "0px" }}
      >
        <motion.div
          className={`grid ${!noJustify && "justify-center"} text-center`}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -10 }}
          exit={{ opacity: 0, display: "none" }}
        >
          {children}
        </motion.div>
      </motion.div>
      {/* all over behind dropdown click closes the dropdown */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[100] h-[100vh]"
          onClick={() => setIsOpen()}
        />
      )}
    </>
  );
}
