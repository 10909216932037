export function ReportPanelView({
  children,
  title,
  subTitle,
}: {
  children: React.ReactNode;
  title: string;
  subTitle: string;
}) {
  return (
    <div className="bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-2 md:p-6 rounded-[14px] flex flex-col md:flex-row gap-2 md:gap-5">
      <div className="flex flex-col bg-[#F5F0F3] py-4 px-[18px] rounded-[10px] gap-1 md:w-[230px]">
        <div className="text-xl font-semibold text-black">{title}</div>
        <div className="text-sm text-[#242424]/70">{subTitle}</div>
      </div>
      <div className="flex-1 p-2 md:p-0">{children}</div>
    </div>
  );
}
