import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/Logo.svg";

export function Header({
  showSignIn = false,
  showSignUp = false,
  isProvider = false,
}) {
  return (
    <div className="px-10 py-4 bg-white/[0.64] backdrop-blur-[27px] border-b border-black/10 justify-between items-center flex">
      <div className="w-24 h-7 justify-center items-center flex">
        <Logo />
      </div>
      <div className="justify-start items-start gap-3 flex">
        {showSignIn && (
          <Link
            to={isProvider ? "/provider/login" : "/login"}
            className="px-4 py-2 !rounded-full bg-primary justify-center items-center gap-2.5 flex"
          >
            <div className="text-white text-sm font-medium leading-normal">
              Sign in
            </div>
          </Link>
        )}

        {showSignUp && (
          <Link
            to={isProvider ? "/provider/register" : "/register"}
            className="px-4 py-2 bg-primary rounded-md justify-center items-center gap-2.5 flex"
          >
            <div className="text-white text-sm font-medium leading-normal">
              Sign up
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}
