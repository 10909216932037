// This component is responsible for playing the audio files and speaking the transcripts one by one from the given list of media files and transcripts.
// first, we will create a state to keep track of the current index of the media files and transcripts.
// We will also create a state to keep track of the current audio element.
// We will create a function to play the next audio file and transcript.
// first it will play first audio file and then speak the first transcript and so on.
// We will also create a function to stop the audio and speech synthesis.
// it should automatically play the next audio file and transcript when the current audio file ends.
// next audio file should be played only when the transcript is finished speaking.

import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { ReactComponent as CallIcon } from "../../assets/icons/headphone.svg";

const CallAudio = ({
  conversations = [],
  currentIndex,
  setCurrentIndex,
}: {
  conversations?: any[];
  currentIndex?: number;
  setCurrentIndex: any;
}) => {
  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const speechRef = React.useRef<SpeechSynthesisUtterance | null>(null);
  const speechIntervalRef = React.useRef<any>();

  const resetAudio = () => {
    setCurrentIndex(undefined);
    setCurrentAudio(null);
    setIsPlaying(false);
    setIsConversationStarted(false);
  };

  const play = () => {
    if (currentIndex === undefined) {
      setCurrentIndex(0);
    }
    const conversation = conversations[currentIndex || 0];
    if (!conversation) {
      resetAudio();
      return;
    }
    if (conversation?.session_url) {
      const audio = new Audio(conversation.session_url);
      setCurrentAudio(audio);
      audio.play();

      audio.onended = () => {
        if ((currentIndex || 0) === conversations.length - 1) {
          resetAudio();
          return;
        }
        setCurrentIndex((currentIndex || 0) + 1);
      };
    } else {
      if (!speechRef.current) {
        speechRef.current = new SpeechSynthesisUtterance(conversation.message);
        const voices = speechSynthesis.getVoices();

        const voice = voices.find(
          (voice) => voice.name === "Google UK English Female"
        );
        if (voice) {
          speechRef.current.voice = voice;
        }
        speechRef.current.pitch = 0.95;
        speechRef.current.lang = "en-US";
        speechRef.current.volume = 0.8;
      } else {
        speechRef.current.text = conversation.message;
      }
      speechSynthesis.speak(speechRef.current);

      speechRef.current.onend = () => {
        if ((currentIndex || 0) === conversations.length - 1) {
          resetAudio();
          return;
        }
        setCurrentIndex((currentIndex || 0) + 1);
      };

      speechIntervalRef.current = setInterval(() => {
        if (!speechSynthesis.speaking) {
          clearInterval(speechIntervalRef.current);
        } else if (!speechSynthesis.paused) {
          speechSynthesis.pause();
          speechSynthesis.resume();
        }
      }, 5000);
    }
    if (!currentIndex && !isConversationStarted) {
      setIsConversationStarted(true);
    }
    setIsPlaying(true);
  };

  const pause = () => {
    const conversation = conversations[currentIndex || 0];
    const isSpeechSynthesis = !conversation.session_url;
    if (!isSpeechSynthesis) {
      currentAudio.pause();
    } else {
      speechSynthesis.pause();
      clearInterval(speechIntervalRef.current);
    }
    setIsPlaying(false);
  };

  const resume = () => {
    const conversation = conversations[currentIndex || 0];
    const isSpeechSynthesis = !conversation.session_url;
    if (!isSpeechSynthesis) {
      currentAudio.play();
    } else {
      speechSynthesis.resume();
      speechIntervalRef.current = setInterval(() => {
        if (!speechSynthesis.speaking) {
          clearInterval(speechIntervalRef.current);
        } else if (!speechSynthesis.paused) {
          speechSynthesis.pause();
          speechSynthesis.resume();
        }
      }, 5000);
    }
    setIsPlaying(true);
  };

  useEffect(() => {
    // on index change
    if (currentIndex) {
      // play after 2 seconds
      setTimeout(() => {
        play();
      }, 700);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (!speechRef.current) {
      speechRef.current = new SpeechSynthesisUtterance("");
      const voices = speechSynthesis.getVoices();

      const voice = voices.find(
        (voice) => voice.name === "Google UK English Female"
      );
      if (voice) {
        speechRef.current.voice = voice;
      }
      speechRef.current.pitch = 0.95;
      speechRef.current.lang = "en-US";
      speechRef.current.volume = 0.8;
      speechSynthesis.speak(speechRef.current);
    }
  }, []);

  return (
    <div>
      {!isConversationStarted ? (
        <Button
          variant="primary"
          className="!rounded-full !py-3 !px-10 w-full md:w-fit"
          onClick={play}
        >
          <CallIcon className="w-[19px] h-[19px]" />
          Listen to session
        </Button>
      ) : (
        <>
          <Button
            variant="primary"
            className="!rounded-full !py-3 !px-10 w-full md:w-fit"
            onClick={!isPlaying ? resume : pause}
          >
            <CallIcon className="w-[19px] h-[19px]" />
            {!isPlaying ? "Resume" : "Pause"}
          </Button>
        </>
      )}
    </div>
  );
};

export default CallAudio;
