import { processingMessages } from "../../constant";

/**
 * pitch: 1.1
 * lang: "en-US"
 * volume: 0.5
 * voice: "Google UK English Male"
 * @param text
 */

export function speak(text: string, shouldCancel = false) {
  // Create a SpeechSynthesisUtterance
  const utterance = new SpeechSynthesisUtterance(text);

  // Select a voice
  const voices = speechSynthesis.getVoices();
  const voice = voices.find(
    (voice) => voice.name === "Google UK English Female"
  );
  console.log("Voice: ", voice, voices);
  if (voice) {
    utterance.voice = voice;
  }
  // Set the pitch, lang, volume
  utterance.pitch = 0.95;
  utterance.lang = "en-US";
  utterance.volume = 0.8;

  // Speak the text

  console.log("Speaking:", utterance);
  if (shouldCancel) {
    speechSynthesis.cancel();
  }

  setTimeout(() => {
    speechSynthesis.speak(utterance);
  }, 0);
  let r = setInterval(() => {
    console.log(speechSynthesis.speaking);
    if (!speechSynthesis.speaking) {
      clearInterval(r);
    } else {
      speechSynthesis.pause();
      speechSynthesis.resume();
    }
  }, 5000);
}

export function getRandomProcessingMessage() {
  return processingMessages[
    Math.floor(Math.random() * processingMessages.length)
  ];
}
