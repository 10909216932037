import { ReactComponent as DisconnectIcon } from "../../assets/icons/disconnect.svg";
import { ReactComponent as VideoIcon } from "../../assets/icons/video-call.svg";
import { ReactComponent as MicIcon } from "../../assets/icons/mic.svg";
import { ReactComponent as SubtitleIcon } from "../../assets/icons/subtitle.svg";

export function ActionButtons({
  onDisconnect,
  onVideo,
  onMic,
  onSubtitle,
  isRecording,
}) {
  return (
    <div className="z-[2] justify-center w-full absolute bottom-12  items-start gap-7 flex">
      {/* <button onClick={onVideo} className="group">
        <VideoIcon className="w-14 h-14 group-hover:[&_circle]:fill-white/90" />
      </button> */}
      <button onClick={onMic} className="group">
        <MicIcon
          className={`w-14 h-14 group-hover:[&_circle]:fill-white/90 ${
            !isRecording ? "[&>path]:fill-[#DDD]" : ""
          }`}
        />
      </button>
      <button onClick={onSubtitle} className="group">
        <SubtitleIcon className="w-14 h-14 group-hover:[&_circle]:fill-white/90" />
      </button>
      <button onClick={onDisconnect} className="group">
        <DisconnectIcon className="w-14 h-14 group-hover:[&_circle]:fill-white/90" />
      </button>
    </div>
  );
}
